import React from "react";


const CustomFooter = () => {
    return (
        <footer className="mt-4 text-center">
            <p>&copy; 2024 Piotr Kuchnowski</p>
        </footer>
    );
}

export default CustomFooter;