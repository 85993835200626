import React from 'react';
import OfferCard from './OfferCard';
import {Container, Row, Col} from 'react-bootstrap';


interface Offer {
    id: number;
    title: string;
    description: string;
    image: string;
}

interface OfferListProps {
    offers: Offer[];
}

const OfferList: React.FC<OfferListProps> = ({ offers }) => {
    return (
      <Container>
        <Col>
          {offers.map((offer, index) => (
            <Row key={index} md={4}>
              <OfferCard offer={offer} />
            </Row>
          ))}
        </Col>
      </Container>
    );
  };

  export default OfferList;