import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import "./Styles/Login.scss";
import logo from "../Assets/logo.svg";


const Protection = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        const storedUsername = process.env.REACT_APP_USERNAME;
        const storedPassword = process.env.REACT_APP_PASSWORD;

        if (username === storedUsername && password === storedPassword) {
            localStorage.setItem('authenticated', 'true');
            navigate('/');
        } else {
            alert('Invalid credentials');
        }
    };

    return (
        <div>
            <Container className="login-background">
                <Container >
                    <img
                        src={logo}
                        alt="doryw.pl logo"
                        className="logo"
                    />
                </Container>
                <Container className="register">
                    <h1>Strona doryw.pl jest aktualnie w trakcie budowy.</h1>
                    <p>Zaloguj się, aby uzyskać dostęp do treści</p>
                </Container>
                <Container className="login-box">
                    <Form>
                        <Form.Group controlId="formBasicLogin" className="form-group">
                            <Form.Label>Adres e-mail:</Form.Label>
                            <Form.Control
                                type="login"
                                placeholder="Wprowadź login"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className="form-group">
                            <Form.Label>Hasło:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Wprowadź hasło"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            className="button"
                            onClick={handleLogin}
                        >
                            Zaloguj
                        </Button>
                    </Form>
                </Container>
            </Container>
        </div>
    );
};

export default Protection;