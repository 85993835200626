import React from 'react';
import Pagination from 'react-bootstrap/Pagination';


interface SquaredPaginationItemProps {
    children: React.ReactNode;
    onClick: () => void;
}


const SquaredPaginationItem = React.forwardRef<HTMLLIElement, SquaredPaginationItemProps>(({ children, onClick, ...props }, ref) => (
    <Pagination.Item
        ref={ref}
        onClick={onClick}
        {...props}
        className="square-pagination-item"
    >
        {children}
    </Pagination.Item>
));

export default SquaredPaginationItem;