import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from './Components/CustomNavbar';
import CustomFooter from './Components/CustomFooter';
import Oferty from './Pages/Offers';
import Login from './Pages/Login';
import Register from './Pages/Register';
import ProtectedRoute from './Components/ProtectedRoute';
import Protection from './Pages/Protection';
import './Styles/App.scss';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Outlet, Navigate } from 'react-router-dom';


const basename = process.env.REACT_APP_BASENAME || '';

const RedirectHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectPath = params.get('redirect');
    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [navigate]);

  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/protection" element={<Protection />} />
      <Route path="" element={<ProtectedRoute element={<div>Home</div>} />} />
      <Route path="/oferty" element={<ProtectedRoute element={<Oferty />} />} />
      <Route path="/logowanie" element={<ProtectedRoute element={<Login />} />} />
      <Route path="/rejestracja" element={<ProtectedRoute element={<Register />} />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  ),
  {
    basename: basename
  }
);

function App() {
  return (
    <>
      <RedirectHandler />
      <header className="App-header">
        <CustomNavbar />
      </header>
      <body className='mt-4'>
        <Outlet />
      </body>
      <footer>
        <CustomFooter />
      </footer>
    </>

  );
}

const AppWithRouter = () => <RouterProvider router={router}></RouterProvider>;

export default AppWithRouter;

