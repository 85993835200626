import React, { useState, useEffect } from 'react';
import OfferList from '../Components/OfferList';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import PaginationBar from '../Components/PaginationBar';
import './Styles/Offers.scss';

const generateOffers = (count: number) => {
  return Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    title: `Oferta ${index + 1}`,
    description: `Opis oferty ${index + 1}`,
    image: 'https://via.placeholder.com/150'
  }));
};

const Oferty = () => {
  const [offerCount, setOfferCount] = useState(225); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [offers, setOffers] = useState(generateOffers(225));
  const [currentPage, setCurrentPage] = useState(1);
  const [offersPerPage, setOffersPerPage] = useState(15); // Default to 15 offers per page

  const handleOffersPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOffersPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when offers per page changes
  };

  useEffect(() => {
    setOffers(generateOffers(offerCount));
  }, [offerCount]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the offers to display on the current page
  const indexOfLastOffer = currentPage * offersPerPage;
  const indexOfFirstOffer = indexOfLastOffer - offersPerPage;
  const currentOffers = offers.slice(indexOfFirstOffer, indexOfLastOffer);

  // Calculate total pages
  const totalPages = Math.ceil(offers.length / offersPerPage);

  return (
    <Container className='page-container'>
      <div className='filter-pane'>
        <Form.Group controlId="offersPerPageSelect" className="mb-3">
          <Form.Label>Ofert na stronę</Form.Label>
          <Form.Control
            as="select"
            value={offersPerPage}
            onChange={(event) => handleOffersPerPageChange(event as unknown as React.ChangeEvent<HTMLSelectElement>)}
          >
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={45}>45</option>
            <option value={60}>60</option>
            <option value={75}>75</option>
            <option value={90}>90</option>
          </Form.Control>
        </Form.Group>
      </div>
      <div className='main-content'>
        <Container>

          <OfferList offers={currentOffers} />

          <PaginationBar totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
          
        </Container>
      </div>
    </Container>
  );
};

export default Oferty;