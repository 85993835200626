import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Styles/OfferCard.scss';

interface Offer {
  id: number;
  title: string;
  description: string;
  image: string;
}

const OfferCard: React.FC<{ offer: Offer }> = (props) => {
  const [isLiked, setIsLiked] = useState(false);

  return (
    <Card className="mb-3 offer-card"> {/* Ensure card takes full width */}
      <Row className='w-100'>
        <Col xs={4} md={4} className="offer-card-img-col">
          <Card.Img
            variant="top"
            src={props.offer.image}
            className="offer-card-img"
          />
        </Col>
        <Col xs={8} md={8}>
          <Card.Body className="d-flex flex-column justify-content-between">
            <div>
              <Card.Title className="mb-1 offer-card-title">{props.offer.title}</Card.Title>
              <Card.Text className="mb-1 offer-card-text">
                {props.offer.description}
              </Card.Text>
            </div>
            <div>
              <Button
                variant="primary"
                size="sm"
                onClick={() => setIsLiked(!isLiked)}
                className='d-flex justify-content-center align-items-center'
                style={{ fontSize: '0.7rem', padding: '0.2rem 0.5rem' }}>
                <i className={isLiked ? 'bi bi-heart-fill white-icon' : 'bi bi-heart white-icon'}></i>
              </Button>
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default OfferCard;