import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "./Styles/Login.scss";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.svg";


const Login = () => {
    return (
        <div>
            <Container className="login-background">
                <Container >
                    <img
                        src={logo}
                        alt="doryw.pl logo"
                        className="logo"
                    />
                </Container>
                <Container className="login-box">
                    <Form>
                        <Form.Group controlId="formBasicEmail" className="form-group">
                            <Form.Label>Adres e-mail:</Form.Label>
                            <Form.Control type="email" placeholder="Wprowadź adres mailowy" />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className="form-group">
                            <Form.Label>Hasło:</Form.Label>
                            <Form.Control type="password" placeholder="Wprowadź hasło" />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="button">
                            Zaloguj
                        </Button>
                    </Form>
                    <Nav as={Link} to={"resetuj-haslo"}>Zapomniałem hasła</Nav>
                </Container>
                <Container className="register">
                    <p>Nie masz jeszcze konta? <Link to="/rejestracja">Zarejestruj się!</Link></p>
                </Container>
            </Container>
        </div>
    );
};

export default Login;