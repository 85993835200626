import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.svg";
import "./Styles/Login.scss";
import { Link } from "react-router-dom";


const Register = () => {
    return (
        <div>
            <Container className="login-background">
                <Container>
                    <img
                        src={logo}
                        alt="doryw.pl logo"
                        className="logo"
                    />
                </Container>
                <Container className="login-box">
                    <Form>
                        <Form.Group controlId="formEmail" className="form-group">
                            <Form.Label>Adres e-mail:</Form.Label>
                            <Form.Control type="email" placeholder="Wprowadź adres mailowy" />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="form-group">
                            <Form.Label>Hasło:</Form.Label>
                            <Form.Control type="password" placeholder="Wprowadź hasło" />
                        </Form.Group>

                        <Form.Group controlId="formPasswordRepeat" className="form-group">
                            <Form.Label>Powtórz hasło:</Form.Label>
                            <Form.Control type="password" placeholder="Powtórz hasło" />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="button">
                            Zarejestruj
                        </Button>
                    </Form>
                </Container>
                <Container className="register">
                    <p>Masz już konto? <Link to="/logowanie">Zaloguj się!</Link></p>
                </Container>
            </Container>
        </div>
    );
};

export default Register;